import {useSession} from "@clerk/clerk-react"
import {Link} from "react-router-dom"
import React from "react"
import {ArrowRightIcon, BackpackIcon, CheckIcon, Cross2Icon, EnterIcon, HomeIcon, RocketIcon, SketchLogoIcon} from "@radix-ui/react-icons"
import {Button} from "@radix-ui/themes"

const featuresObj = [
    {
        number: "1",
        header: "Customizable Categories",
        title: "You can create and customize categories/tags for your bookmarks. This helps in organizing bookmarks efficiently and finding them quickly."
    },
    {
        number: "2",
        header: "Visual Thumbnails",
        title: "Visual thumbnails provide a quick visual reference making it easier for you to identify your bookmarks at a glance."
    },
    {
        number: "3",
        header: "Search and Filtering",
        title: "You have a robust search functionality and filtering options. You can search and filter bookmarks by title, tags/categories, or description."
    },
    {
        number: "4",
        header: "Accessibility Across Devices",
        title: "It is a web application that is accessible across your multiple devices, so you can access your bookmarks from anywhere, whether on a computer, tablet, or smartphone."
    },
    {
        number: "5",
        header: "Sharing and Collaboration",
        title: "Share bookmarks with others and collaborate on collections. This is especially useful for teams and groups working on shared projects or if you want to share your favorite links with friends and family. Coming soon..."
    },
]

const advantagesData = [
    {
      src: "/img_visual_bookmarks.webp",
      alt: "Visual bookmarks",
      title: "Visual bookmarks",
      description: "Visual Bookmarks is a powerful tool that allows you to organize and manage your favorite web links with ease. With visually appealing thumbnails and customizable categories, finding and accessing your bookmarks has never been easier."
    },
    {
      src: "/img_friendly_to_adhd.webp",
      alt: "ADHD Friendly",
      title: "ADHD Friendly",
      description: "Our user interface is designed with ADHD users in mind, providing a clean, distraction-free environment. Features like customizable categories and visual organization help maintain focus and streamline navigation."
    },
    {
      src: "/assets/53.webp",
      alt: "You own your data",
      title: "You own your data",
      description: "We prioritize your privacy and security, ensuring that your data is never shared. You can easily download your bookmarks and categories at any time, giving you complete control over your information."
    }
]

const personalPlanObj = [
    { text: "100 categories", iconColor: "green" },
    { text: "100,000 bookmarks", iconColor: "green" },
    { text: "Search and filtering", iconColor: "green" },
    { text: "Customizations", iconColor: "green" },
    { text: "Ad-free", iconColor: "green" },
    { text: "Import from supported 3rd-party services", iconColor: "green" },
    { text: "Shared categories", iconColor: "red" },
    { text: "Public collections", iconColor: "red" },
    { text: "Priority support", iconColor: "red" }
]

const familyPlanObj = [
    { text: "600 categories for the family", iconColor: "green" },
    { text: "600,000 bookmarks for the family", iconColor: "green" },
    { text: "Search and filtering", iconColor: "green" },
    { text: "Customizations", iconColor: "green" },
    { text: "Ad-free", iconColor: "green" },
    { text: "Import from supported 3rd-party services", iconColor: "green" },
    { text: "Shared categories", iconColor: "green" },
    { text: "Public collections", iconColor: "red" },
    { text: "Priority support", iconColor: "red" }
]

const teamPlanObj = [
    { text: "500 categories", iconColor: "green" },
    { text: "1 000,000 bookmarks", iconColor: "green" },
    { text: "Search and filtering", iconColor: "green" },
    { text: "Customizations", iconColor: "green" },
    { text: "Ad-free", iconColor: "green" },
    { text: "Import from supported 3rd-party services", iconColor: "green" },
    { text: "Unlimited shared categories", iconColor: "green" },
    { text: "Public collections", iconColor: "green" },
    { text: "Priority support", iconColor: "green" }
]

const IndexPage = () => {
    const {isLoaded, session, isSignedIn} = useSession()

    const FeatureCard = ({ src, alt, title, description }: any) => (
        <div className="p-6">
          <img className="object-cover object-center w-full mb-8 rounded-xl" src={src} alt={alt} />
          <h1 className="mx-auto mb-3 text-2xl font-semibold leading-none tracking-tight text-gray-dark lg:text-3xl">
            {title}
          </h1>
          <p className="mx-auto leading-relaxed text-[#000]">
            {description}
          </p>
        </div>
    )

    return (
        <>
            <header className="bg-[#fdf0d5] sticky top-0 z-50 shadow-lg">
                <div className="container mx-auto flex justify-between items-center py-4">
                    <div className="flex items-center">
                        <img src="/apple-touch-icon.png" alt="Logo" className="h-14 w-auto mr-4"/>
                        <div className="flex flex-col">
                            <span className="text-sm font-[700] text-[#06304b] leading-5">Possum's</span>
                            <span className="text-[#06304b] text-2xl font-[800] leading-4 tracking-wider">Links</span>
                        </div>
                    </div>
                    <div className="flex md:hidden">
                        <button id="hamburger" className="text-white focus:outline-none">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="hidden lg:flex items-center space-x-4">
                        {isSignedIn ?
                            <Link
                                to="/bookmarks"
                                className="flex items-center gap-2 px-5 py-3 bg-[#0d3b66] text-sm text-white font-medium rounded-full"
                            >
                                Go to bookmarks
                                <ArrowRightIcon/>
                            </Link>
                            :
                            <div className="flex gap-3">
                                <Link
                                    to="/sign-up"
                                    className="flex items-center gap-2 px-5 py-3 border-[1px] border-[#0d3b66] text-sm text-[#0d3b66] font-іуьшищдв rounded-full hover:bg-[#0d3b66] hover:text-white"
                                >
                                    Sign up
                                </Link>
                                <Link
                                    to="/sign-in"
                                    className="flex items-center gap-2 px-5 py-3 bg-[#0d3b66] text-sm text-white font-medium rounded-full hover:bg-[#f95738] hover:text-white"
                                >
                                    Log in
                                    <EnterIcon/>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            </header>
            <section id="home" className="py-8 overflow-hidden">
                <div className="container flex flex-col md:flex-row h-[436px] items-center justify-between">
                    <div className="flex flex-col gap-10 w-[35%] mx-auto">
                        <h2 className="text-5xl font-bold text-[#0d3b66]">Possum's <span
                            className="text-[#f95738]">Links</span>
                        </h2>
                        <p className="text-[#000] leading-7">Effortlessly manage and organize your visual bookmarks with our
                            intuitive
                            service. Save, categorize, and access your favorite online content seamlessly, all in one
                            place. Enhance your browsing experience with visual bookmarks designed for quick retrieval
                            and easy management.
                        </p>
                        {
                            isSignedIn ?
                                <Link
                                    to="/bookmarks"
                                    className="animate-bounce flex gap-2 items-center px-6 py-4 mt-1 w-fit text-sm text-white font-medium bg-[#f95738] uppercase rounded-full cursor-pointer hover:bg-[#0d3b66]">
                                    Go to bookmarks <ArrowRightIcon/>
                                </Link>
                                :
                                <Link
                                    to="/sign-up"
                                    className="animate-bounce flex gap-2 items-center px-6 py-4 mt-1 w-fit text-sm text-white font-medium bg-[#f95738] uppercase rounded-full cursor-pointer hover:bg-[#0d3b66]">
                                    Try visual bookmarks <ArrowRightIcon/>
                                </Link>
                        }
                    </div>
                    {/*Right column: Responsive image*/}
                    <div className="md:w-[50%] md:!mr-[-120px] lg:!mr-[-233px]">
                        <img src="/assets/home-page-picture.png" alt="Image"
                             className="w-full h-[500px] object-cover opacity-90"/>
                    </div>
                </div>
            </section>
            <section className="py-16 bg-[#fdf0d5]">
                <div className="text-center">
                    <h2 className="mb-2 text-5xl font-bold">
                        Why you should try our app?
                    </h2>
                </div>
                <div className="relative items-center w-full px-5 mx-auto max-w-7xl">
                    <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3">
                        {advantagesData.map((card, index) => (
                            <FeatureCard
                                key={`${card.title}-${index}`}
                                src={card.src}
                                alt={card.alt}
                                title={card.title}
                                description={card.description}
                            />
                        ))}
                    </div>
                </div>
            </section>
            <section id="about-us" className="py-16">
                <div className="container mx-auto flex py-2 flex-col md:flex-row items-center justify-around">
                    <div className="flex flex-col items-start space-y-8">
                        <h2 className="flex items-center self-center gap-4 leading-10 text-5xl font-bold">
                            <RocketIcon width="30" height="30" />
                            Our features
                        </h2>
                        {/* <img src="/assets/35.webp" alt="Our features" */}
                        <img src="/assets/54.webp" alt="Our features"
                             className="w-full md:max-w-md rounded-xl bg-gray-100 shadow-lg opacity-90"/>
                    </div>
                    <div className="flex flex-col gap-6 w-1/2">
                        <ol className="space-y-7">
                            {featuresObj.map((feature, index) => (
                                <li key={`${feature.header}-${index}`} className="flex gap-3">
                                    <div className="bg-[#f97649] text-white rounded-full mt-0.5 min-w-5 h-5 text-xs font-semibold flex items-center justify-center opacity-75">{feature.number}</div>
                                    <div className="flex flex-col gap-1">
                                        <p className="font-bold text-[#0d3b66]">{feature.header}</p>
                                        <span className="text-[#000] font-[400]">{feature.title}</span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </section>
            <section className="py-16 bg-[#fdf0d5]">
                <div className="items-center w-full mx-auto max-w-7xl space-y-8">
                    <div className="flex items-center gap-4 w-fit mx-auto">
                        <BackpackIcon height={30} width={30} color="#0d3b66" className="mt-2"/>
                        <h2 className="text-5xl font-bold leading-10">Our plans</h2>
                    </div>
                    <p className="flex justify-center text-[#0d3b66] font-bold">* All plans come with a 14-day free trial. Charges begin after the trial
                        period ends.</p>
                    <div className="flex justify-between">
                        <div
                            className="w-[30%] space-y-7 p-10 h-fit !min-h-[622px] rounded-xl bg-white hover:border-[2px] transform transition-transform duration-500 hover:scale-110 hover:shadow-xl hover:border-t-[3px] hover:border-[#f97649]">
                            <RocketIcon height={45} width={45} color="#f97649" className="mx-auto"/>
                            <div className="flex flex-col items-center space-y-1.5">
                                <h3 className="font-medium text-[#f97649] text-3xl text-center">Personal</h3>
                                <h3 className="p-4 text-5xl text-[#0d3b66] font-bold text-center bg-[#e9f4ff] rounded-full">$9.99</h3>
                                <p className="font-medium">per month</p>
                            </div>
                            <ul className="text-md space-y-2 list-none">
                                {personalPlanObj.map((item, index) => (
                                    <li key={`${item.text}-${index}`}
                                        className="flex items-center gap-2 text-[#000] leading-5">
                                        {item.iconColor === "green" ? (
                                            <CheckIcon color={item.iconColor}/>
                                        ) : (
                                            <Cross2Icon color={item.iconColor}/>
                                        )}
                                        {item.text}
                                    </li>
                                ))}
                            </ul>
                            <div className="flex justify-center">
                                <Link
                                    to={ isSignedIn ? "/bookmarks" : "/sign-up" }
                                    className="flex items-center gap-2 w-fit px-5 py-3 bg-[#0d3b66] text-sm text-white font-medium rounded-full hover:bg-[#f95738] hover:text-white"
                                >
                                    Get started
                                    <ArrowRightIcon/>
                                </Link>
                            </div>
                        </div>
                        <div
                            className="relative w-[30%] space-y-7 p-10 h-fit !min-h-[622px] rounded-xl bg-white hover:border-[2px]transform transition-transform duration-500 hover:scale-110 hover:shadow-xl hover:border-t-[3px] hover:border-[#f97649]">
                            <HomeIcon height={45} width={45} color="#f97649" className="mx-auto"/>
                            <div className="flex flex-col items-center space-y-1.5">
                                <h3 className="font-medium text-[#f97649] text-3xl text-center">Family</h3>
                                <h3 className="p-4 text-5xl text-[#0d3b66] font-bold text-center bg-[#e9f4ff] rounded-full">$39.99</h3>
                                <p className="font-medium">per month for a group of 6</p>
                            </div>
                            <ul className="text-md space-y-2 list-none">
                                {familyPlanObj.map((item, index) => (
                                    <li key={`${item.text}-${index}`}
                                        className="flex items-center gap-2 text-[#000] leading-5">
                                        {item.iconColor === "green" ? (
                                            <CheckIcon color={item.iconColor}/>
                                        ) : (
                                            <Cross2Icon color={item.iconColor}/>
                                        )}
                                        {item.text}
                                    </li>
                                ))}
                            </ul>
                            <Button
                                className="flex items-center gap-2 mx-auto p-5 bg-[#0d3b66] text-sm text-white font-medium rounded-full cursor-pointer hover:bg-[#f97649] transform transition-transform duration-200 hover:scale-110">
                                Get started
                            </Button>
                            <div
                                className="absolute top-[-30px] left-0 z-2 w-full !min-h-[665px] bg-white opacity-[75%] rounded-xl"/>
                            <p className="absolute z-10 top-[38%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-2xl text-center font-semibold">
                                Coming soon...
                            </p>
                        </div>
                        <div
                            className="relative w-[30%] space-y-7 p-10 h-fit !min-h-[622px] rounded-xl bg-white hover:border-[2px]transform transition-transform duration-500 hover:scale-110 hover:shadow-xl hover:border-t-[3px] hover:border-[#f97649]">
                            <SketchLogoIcon height={45} width={45} color="#f97649" className="mx-auto"/>
                            <div className="flex flex-col items-center space-y-1.5">
                                <h3 className="font-medium text-[#f97649] text-3xl text-center">Team</h3>
                                <h3 className="p-4 text-5xl text-[#0d3b66] font-bold text-center bg-[#e9f4ff] rounded-full">$19.99</h3>
                                <p className="font-medium">per month, for each team member</p>
                            </div>
                            <ul className="text-md space-y-2 list-none">
                                {teamPlanObj.map((item, index) => (
                                    <li key={`${item.text}-${index}`}
                                        className="flex items-center gap-2 text-[#000] leading-5">
                                        {item.iconColor === "green" ? (
                                            <CheckIcon color={item.iconColor}/>
                                        ) : (
                                            <Cross2Icon color={item.iconColor}/>
                                        )}
                                        {item.text}
                                    </li>
                                ))}
                            </ul>
                            <Button
                                className="flex items-center gap-2 mx-auto p-5 bg-[#0d3b66] text-sm text-white font-medium rounded-full cursor-pointer hover:bg-[#f97649] transform transition-transform duration-200 hover:scale-110">
                                Get started
                            </Button>
                            <div
                                className="absolute top-[-30px] left-0 z-2 w-full !min-h-[665px] bg-white opacity-[75%] rounded-xl"/>
                            <p className="absolute z-10 top-[38%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-2xl text-center font-semibold">
                                Coming soon...
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndexPage