import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import addBookmarkModalReducer from './reducers/addBookmarkModalReducer';
import {editCategoryModalReducer} from "./reducers/editCategoryModalReducer";
import {createCategoryModalReducer} from "./reducers/createCategoryModalReducer";
import {ApplicationConfigReducer} from "./reducers/ApplicationConfigReducer";
import ApplicationConfig from "./interfaces/ApplicationConfig";

// Combine all the reducers
const rootReducer = combineReducers({
    addBookmarkModal: addBookmarkModalReducer,
    editCategoryModal: editCategoryModalReducer,
    createCategoryModal: createCategoryModalReducer,
    applicationConfig: ApplicationConfigReducer,
});

export interface RootState {
    addBookmarkModal: {
        isOpen: boolean;
    };
    editCategoryModal: {
        isOpen: boolean;
    };
    createCategoryModal: {
        isOpen: boolean;
    };
    applicationConfig: ApplicationConfig;
}

// Create the store with the combined reducers
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;