import React, {useEffect, useState} from 'react';
import CategoryEntity from "../interfaces/CategoryEntity";
import {DragDropContext, Droppable, Draggable} from '@hello-pangea/dnd';
import Navigation from "../components/Navigation";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {editCategoryModalAction} from "../actions/editCategoryModalAction";
import EditCategoryModal from "../modals/EditCategoryModal";
import {EDIT_CATEGORY_MODAL_CLOSED} from "../reducers/editCategoryModalReducer";
import {apiCreateCategory, apiFetchCategories, apiUpdateCategoriesOrder, apiUpdateCategory} from "../interfaces/API";
import CategoriesPayload from "../interfaces/CategoriesPayload";
import ThemeEntity from "../interfaces/ThemeEntity";
import UpdateCategoryRequest from "../interfaces/UpdateCategoryRequest";
import {Button} from "@radix-ui/themes";
import {BookmarkIcon} from "@radix-ui/react-icons";
import {CREATE_CATEGORY_MODAL_CLOSED, CREATE_CATEGORY_MODAL_OPEN} from "../reducers/createCategoryModalReducer";
import AddCategoryModal from "../modals/AddCategoryModal";
import {RootState} from "../store";

const CategoriesPage: React.FC = () => {
    const dispatch = useDispatch();
    const isEditCategoryModalOpen = useSelector((state: RootState) => state.editCategoryModal.isOpen);
    const isAddCategoryModalOpen = useSelector((state: RootState) => state.createCategoryModal.isOpen);
    const handleEditCategoryClick = (category: CategoryEntity) => {
        setSelectedCategory(category);
        dispatch(editCategoryModalAction(category));
    };
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState<CategoryEntity | null>(null);
    const [getCategories, setCategories] = useState<CategoryEntity[]>([]);
    const [getThemes, setThemes] = useState<ThemeEntity[]>([]);
    const [orderChanged, setOrderChanged] = useState(false);

    const handleDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const reorderedCategories = Array.from(getCategories);
        const [removed] = reorderedCategories.splice(result.source.index, 1);
        reorderedCategories.splice(result.destination.index, 0, removed);
        setCategories(reorderedCategories);
        setOrderChanged(true);
    };

    const fetchCategories = async () => {
        try {
            const response = await apiFetchCategories();
            const data: CategoriesPayload = await response.json();
            setCategories(data.categories);
            setThemes(data.themes);
            // setThemes(staticThemes);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    return (
        <div>
            {/*{isLoading && <SpinnerTransparent/>}*/}

            <Navigation/>

            <div className="min-h-full">
                <div className="py-10 space-y-4">
                    <main className="space-y-4 flex">
                        <div className="flex justify-between w-[100%] mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div className="space-y-8 w-[48%] p-10 rounded-lg bg-white shadow-lg">
                                <div className="space-y-5">
                                    <h2 className="text-2xl font-bold mb-4">Instructions</h2>
                                    <ul className="pl-5">
                                        <li className="text-md leading-8">Drag and drop categories to reorder them.
                                            Click the 'Save order' button if you want to keep the new order.
                                        </li>
                                        <li className="text-md leading-8">Click on a category to edit its details.</li>
                                        <li className="text-md leading-8">To change the color and the name click on the
                                            Category.
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex flex-col justify-center items-center gap-3 mt-3 p-6 bg-primary3">
                                    <p className="text-md leading-8">Click on "Add category" to start working:</p>
                                    <Button
                                        onClick={() => dispatch({type: CREATE_CATEGORY_MODAL_OPEN})}
                                        size="3"
                                        className="my-btn"
                                        // className="bg-primary text-primary2"
                                    >
                                        <BookmarkIcon/> Create category
                                    </Button>
                                </div>
                            </div>
                            <div className="flex flex-col items-center w-[48%]">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="categories" direction="vertical">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}
                                                 className="flex flex-col gap-2.5 w-full">
                                                {getCategories.map((category: CategoryEntity, index: number) => (
                                                    <Draggable key={category.id} draggableId={category.id.toString()}
                                                               index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                key={category.id}
                                                                className={classNames("relative inline-flex items-center rounded-[6px] px-3 py-2 text-lg font-light hover:bg-gray-100 category-chip")}
                                                                style={{
                                                                    backgroundColor: category.background_color,
                                                                    color: category.text_color,
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                                onClick={() => handleEditCategoryClick(category)}
                                                            >
                                                                {category.name}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <div className="py-10">
                                    {orderChanged &&
                                        <Button
                                            size="3"
                                            className="my-btn"
                                            onClick={() => apiUpdateCategoriesOrder(getCategories)}
                                        >
                                            Save order
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

                {selectedCategory && (
                    <EditCategoryModal
                        isOpen={isEditCategoryModalOpen}
                        onRequestClose={() => dispatch({type: EDIT_CATEGORY_MODAL_CLOSED})}
                        category={selectedCategory}
                        themes={getThemes}
                        onSaveCategory={async (category) => {
                            setIsLoading(true); // Show spinner
                            let req: UpdateCategoryRequest = {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                theme_id: category.theme_id
                            };
                            await apiUpdateCategory(req);
                            setIsLoading(false); // Hide spinner
                            dispatch({type: EDIT_CATEGORY_MODAL_CLOSED});
                            await fetchCategories(); // Refresh categories
                        }}/>
                )}

                {!isLoading && (<AddCategoryModal
                    isOpen={isAddCategoryModalOpen}
                    themes={getThemes}
                    onRequestClose={() => dispatch({type: CREATE_CATEGORY_MODAL_CLOSED})}
                    onSaveCategory={async (category) => {
                        setIsLoading(true); // Show spinner
                        await apiCreateCategory(category);
                        setIsLoading(false); // Hide spinner
                        dispatch({type: CREATE_CATEGORY_MODAL_CLOSED});
                        await fetchCategories(); // Refresh categories
                    }}
                />)}

            </div>
        </div>
    )
}

export default CategoriesPage;