import React, {useEffect, useRef, useState} from 'react';

import BookmarkEntity from "../interfaces/BookmarkEntity";
import Card from "./Card";
import {Button, Text} from "@radix-ui/themes";
import ApplicationConfig from "../interfaces/ApplicationConfig";

interface CardsProps {
    bookmarks: BookmarkEntity[];
    config: ApplicationConfig;
    filter_value: string;
    selected_category_id: string;
    bookmarks_refetch_function: () => void;
}

const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);
        const handler = (event: MediaQueryListEvent) => setMatches(event.matches);

        mediaQuery.addEventListener('change', handler);
        return () => mediaQuery.removeEventListener('change', handler);
    }, [query]);

    return matches;
};

const Cards = ({bookmarks, config, filter_value, selected_category_id, bookmarks_refetch_function}: CardsProps) => {
    const listRef = useRef<HTMLUListElement>(null);

    const isSmall = useMediaQuery('(max-width: 640px)');
    const isMedium = useMediaQuery('(min-width: 641px) and (max-width: 1024px)');
    const isLarge = useMediaQuery('(min-width: 1025px)');
    
    const deffaultClassName: string = "grid grid-cols-1 gap-x-3 gap-y-4";

    const [itemsPerPage, setItemsPerPage] = useState(0);

    useEffect(() => {
        if (listRef.current) {
            let sm_grid_size: number = 0;
            let md_grid_size: number = 0;
            let lg_grid_size: number = 0;

            if (config.cardSize === "small") {
                listRef.current.className = "grid grid-cols-1 gap-x-3 gap-y-4 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8";
                sm_grid_size = 4;
                md_grid_size = 6;
                lg_grid_size = 8;
            } else if (config.cardSize === "medium") {
                listRef.current.className = "grid grid-cols-1 gap-x-3 gap-y-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6";
                sm_grid_size = 2;
                md_grid_size = 4;
                lg_grid_size = 6;
            } else if (config.cardSize === "large") {
                listRef.current.className = "grid grid-cols-1 gap-x-3 gap-y-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4";
                sm_grid_size = 1;
                md_grid_size = 2;
                lg_grid_size = 4;
            }

            setItemsPerPage(isSmall ? sm_grid_size * config.numberOfBookmarksInColumn :
                isMedium ? md_grid_size * config.numberOfBookmarksInColumn :
                    isLarge ? lg_grid_size * config.numberOfBookmarksInColumn : 0);
        }
    }, [config, isSmall, isMedium, isLarge]);

    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredBookmarks =
        bookmarks
            .filter((bookmark: BookmarkEntity): boolean => {
                return filter_by_search(bookmark, filter_value)
            })
            .filter((bookmark: BookmarkEntity): boolean => {
                return filter_by_category(bookmark, selected_category_id)
            });
    const slicedBookmarks =
        !config.endLessFeed ?
            filteredBookmarks.slice(indexOfFirstItem, indexOfLastItem)
            : filteredBookmarks;

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (!config.endLessFeed) {
                switch (event.key) {
                    case 'ArrowLeft':
                        if (currentPage !== 1) {
                            paginate(currentPage - 1);
                        }
                        break;
                    case 'ArrowRight':
                        if (indexOfLastItem < filteredBookmarks.length) {
                            paginate(currentPage + 1);
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentPage, filteredBookmarks.length]);


    return (
        <>
            <ul ref={listRef} className={`${deffaultClassName}`}>
                {
                    slicedBookmarks
                        .map((bookmarkEntity) => (
                            <li
                                key={bookmarkEntity.id}
                                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border-[1px] cardLi"
                            >
                                <Card bookmark={bookmarkEntity} refetchBookmarks={bookmarks_refetch_function}/>
                            </li>
                        ))
                }
            </ul>

            { !config.endLessFeed && <div className="mx-auto">
                <nav
                    className="flex items-center justify-between py-5"
                    aria-label="Pagination"
                >
                    <Text size="3">
                        Showing <span
                        className="font-medium">{slicedBookmarks.length > 0 ? indexOfFirstItem + 1 : 0}</span> to <span
                        className="font-medium">{indexOfFirstItem + slicedBookmarks.length}</span> of{' '}
                        <span className="font-medium">{filteredBookmarks.length}</span> cards
                    </Text>
                    <div className="flex flex-1 justify-end">
                        <Button
                            // color="gray"
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        &nbsp;
                        <Button
                            // color="gray"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={indexOfLastItem >= filteredBookmarks.length}
                        >
                            Next
                        </Button>
                    </div>
                </nav>
            </div>
            }
        </>
    )
}

function filter_by_search(bookmark: BookmarkEntity, s: string): boolean {
    const regex = RegExp(s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i");
    return bookmark.header.search(regex) >= 0 || bookmark.description.search(regex) >= 0 || bookmark.site_name.search(regex) >= 0 || bookmark.url.search(regex) >= 0;
}

function filter_by_category(bookmark: BookmarkEntity, category_id: string): boolean {
    return bookmark.category_id === category_id || category_id === "";
}

export default Cards;
