import React, {useEffect} from 'react';
import {useState} from 'react'
import CategoryEntity from "../interfaces/CategoryEntity";
import {BookmarkIcon, CrossCircledIcon} from "@radix-ui/react-icons";
import {Dialog, Text, TextField, Button, Flex, Select} from '@radix-ui/themes';

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    categories: CategoryEntity[];
    selected_category_id: string;
    onSaveBookmark: (url: string, category_id: string) => void;
}

const AddBookmarkModal: React.FC<Props> = ({isOpen, onRequestClose, categories, selected_category_id, onSaveBookmark}) => {
    const [url, setUrl] = React.useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(selected_category_id)

    useEffect(() => {
        setSelectedCategoryId(selected_category_id.length > 0 ? selected_category_id : categories[0].id);
    }, [selected_category_id]);

    const cleanUpAndClose = () => {
        cleanUpForm();
        onRequestClose();
    }

    const cleanUpForm = () => {
        setUrl('');
        // setDescription('');
        // setQuery('');
        setSelectedCategoryId(selected_category_id.length > 0 ? selected_category_id : categories[0].id);
    }

    return (
        <Dialog.Root open={isOpen}>
            <Dialog.Content className="my-dialog" onEscapeKeyDown={cleanUpAndClose}>
                <Dialog.Title>Add new bookmark</Dialog.Title>
                <Dialog.Description size="2" my="4">
                    A new bookmark will be added to your collection.
                </Dialog.Description>
                <Flex direction="column" gap="4">
                    <label>
                        <Text as="div" size="2" mb="1" weight="medium">
                            Category
                        </Text>
                        <Select.Root defaultValue={selectedCategoryId} onValueChange={setSelectedCategoryId}>
                            <Select.Trigger className="my-input"/>
                            <Select.Content position="popper">
                                {categories.length > 0 && (
                                    categories.map((category: CategoryEntity) => (
                                        <Select.Item value={category.id} key={category.id}>{category.name}</Select.Item>
                                    ))
                                )}
                            </Select.Content>
                        </Select.Root>
                    </label>

                    <label>
                        <Text as="div" size="2" mb="1" weight="medium">
                            URL
                        </Text>
                        <TextField.Root
                            defaultValue={url}
                            className="my-input"
                            placeholder="https://"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </label>
                </Flex>
                <Flex gap="3" mt="6" justify="end">
                    <Button
                        onClick={cleanUpAndClose}
                        className="my-btn"
                        color="gray"
                    >
                        <CrossCircledIcon/> Cancel
                    </Button>
                    <Button
                        className="my-btn"
                        disabled={url === ''}
                        onClick={() => {
                            cleanUpForm();
                            onSaveBookmark(url, selectedCategoryId);
                        }}
                    >
                        <BookmarkIcon/> Add bookmark
                    </Button>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default AddBookmarkModal;