import React from "react";
import {Link} from "react-router-dom";
import {Heading, Text} from "@radix-ui/themes";

export default function TermsAndConditionsPage() {
    return (
        <>
            <div>
                <div className="mx-auto max-w-7xl px-6 py-14">
                    <Link to={"/"} className="flex justify-center">
                        <img src="/apple-touch-icon.png" alt="psmslnks logo"/>
                    </Link>
                    <div className="mx-auto max-w-4xl">
                        <Heading as="h1" className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Terms and
                            Conditions</Heading>

                        <b className="text-sm font-bold leading-10 tracking-tight text-gray-500">Last Updated:
                            01.02.2024</b>

                        <Text as="p">These Terms and Conditions ("Terms") govern your use of Psmslnks.com (the "Service") operated
                            by Aurora
                            Algorithmics ("us," "we," or "our").</Text>

                        <Text as={"p"}>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with
                            any part of
                            the Terms, then you may not access the Service.</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Use of the
                            Service</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">
                            {/*<li>You must be at least 18 years old to use the Service. By using the Service, you*/}
                            {/*    represent and*/}
                            {/*    warrant that you are at least 18 years old.*/}
                            {/*</li>*/}
                            <li>You agree to use the Service only for lawful purposes and in accordance with these
                                Terms.
                            </li>
                            <li>You agree not to access or use the Service in any manner that could damage, disable,
                                overburden, or
                                impair the Service or interfere with any other party's use and enjoyment of the Service.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Intellectual
                            Property</Heading>

                        <Text as={"p"}>
                            The Service and its original content, features, and functionality are owned by Pmsmslnks.com and are protected by international copyright, trademark,
                                patent, trade
                                secret, and other intellectual property or proprietary rights laws.
                        </Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Links to Other
                            Websites</Heading>

                        <ul className={"list-disc list-inside"}>
                            <li>Our Service may contain links to third-party websites or services that are not owned or
                                controlled
                                by Psmslnks.com.
                            </li>
                            <li>Psmslnks.com has no control over, and assumes no responsibility for, the content,
                                privacy policies,
                                or practices of any third-party websites or services. You further acknowledge and agree
                                that [Your
                                Company/Organization Name] shall not be responsible or liable, directly or indirectly,
                                for any
                                damage or loss caused or alleged to be caused by or in connection with the use of or
                                reliance on any
                                such content, goods, or services available on or through any such websites or services.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Limitation of
                            Liability</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">
                            In no event shall Psmslnks.com, nor its directors, employees,
                            partners, agents,
                            suppliers, or affiliates, be liable for any indirect, incidental, special,
                            consequential, or
                            punitive damages, including without limitation, loss of profits, data, use, goodwill, or
                            other
                            intangible losses, resulting from
                            <li>(i) your access to or use of or inability to access or
                                use the
                                Service;</li>
                            <li>(ii) any conduct or content of any third party on the Service;</li>
                            <li>(iii) any
                                content obtained
                                from the Service;
                            </li>
                            <li>and (iv) unauthorized access, use, or alteration of your transmissions
                                or content,
                                whether based on warranty, contract, tort (including negligence), or any other legal
                                theory, whether
                                or not we have been informed of the possibility of such damage, and even if a remedy set
                                forth
                                herein is found to have failed of its essential purpose.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Governing Law</Heading>

                        <Text as={"p"} className="list-disc list-inside marker:text-gray-900">
                            These Terms shall be governed and construed in accordance with the laws of Sweden, without
                                regard to its conflict of law provisions.
                        </Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Changes to These
                            Terms</Heading>
                        <ul className="list-disc list-inside marker:text-gray-900">
                            <Text as={"p"}>We reserve the right, at our sole discretion, to modify or replace these Terms at any
                                time. If a
                                revision is material, we will try to provide at least 30 days' notice prior to any new
                                terms taking
                                effect. What constitutes a material change will be determined at our sole discretion.
                            </Text>
                        </ul>

                    </div>
                </div>
            </div>
        </>
    )
}