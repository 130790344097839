import React, {useState} from "react"
import {Link} from "react-router-dom"
import {Button, Heading} from "@radix-ui/themes"
import { useFormspark } from "@formspark/use-formspark"

const FORMSPARK_FORM_ID = "zUG73tVFC"

const ContactUsPage = () => {
    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    })
    const [message, setMessage] = useState("")

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await submit({ message })
        alert("Form submitted")
    }
    
    return (
        <div className="flex items-center justify-center h-[100%] w-[100%]">
            <div className="mx-auto h-fit w-[50%] max-w-7xl p-10 border-[1px] border-gray-200 rounded-2xl">
                <div className="mx-auto max-w-4xl px-6">
                    <Link to={"/"} className="flex justify-center">
                        <img src="/apple-touch-icon.png" alt="psmslnks logo" className="h-24"/>
                    </Link>
                    <Heading as={"h1"} className="text-2xl font-bold leading-10 tracking-tight text-gray-800">Contact Us</Heading>
                    <div className="flex flex-col justify-center" id="contact-us-form">
                        <form onSubmit={onSubmit}>
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" id="name" name="name" placeholder="Add your full name" required />
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" placeholder="example@gmail.com" required />
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder="Here should be your message..."
                                        required
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                                <Button
                                    type="submit"
                                    disabled={submitting}
                                    className="w-[14rem] p-6 mx-auto bg-[#0d3b66] rounded-full cursor-pointer hover:bg-[#f95738]"
                                >
                                    SEND
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsPage