// Action
export const OPEN_BOOKMARK_MODAL = 'OPEN_BOOKMARK_MODAl';
export const CLOSE_BOOKMARK_MODAL = 'CLOSE_BOOKMARK_MODAl';

// Reducer
const initialState = { isOpen: false };

interface Action {
    type: string;
    payload?: any;
}

export default function addBookmarkModalReducer(state = initialState, action: Action) {
    switch (action.type) {
        case OPEN_BOOKMARK_MODAL:
            return { ...state, isOpen: true };
        case CLOSE_BOOKMARK_MODAL:
            return { ...state, isOpen: false };
        default:
            return state;
    }
}