import React from "react";
import {Disclosure} from "@headlessui/react";
import {UserButton} from "@clerk/clerk-react";
import {useLocation, Link} from 'react-router-dom';
import {TabNav} from '@radix-ui/themes';

export default function Navigation() {
    const location = useLocation();
    return (
        <Disclosure as="nav" className="bg-white border border-bottom-[1px] border-gray-100">
            {({open}) => (
                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                    <div className="flex h-16 justify-between">
                        <div className="flex flex-shrink-0 items-center">
                            <img
                                className="hidden h-10 w-auto lg:block"
                                src="/psmslnks-logo-menu.png"
                                alt="Possum's Links"
                            />
                        </div>
                        <div className="flex flex-shrink-0 items-center">
                            <TabNav.Root size="2">
                                <TabNav.Link active={location.pathname === '/bookmarks'} asChild>
                                    <Link to="/bookmarks" className="my-navlink">Bookmarks</Link>
                                </TabNav.Link>
                                <TabNav.Link active={location.pathname === '/categories'} asChild>
                                    <Link to="/categories" className="my-navlink">Categories</Link>
                                </TabNav.Link>
                                <TabNav.Link active={location.pathname === '/settings'} asChild>
                                    <Link to="/settings" className="my-navlink">Settings</Link>
                                </TabNav.Link>
                            </TabNav.Root>

                        </div>
                        <div className="hidden lg:ml-4 lg:flex lg:items-center">
                            <UserButton afterSignOutUrl='/sign-in'/>
                        </div>
                    </div>
                </div>
            )}
        </Disclosure>
    )
}
