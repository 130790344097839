import React from "react";
import {Link} from "react-router-dom";
import {Heading, Text} from "@radix-ui/themes";
import {useSession} from "@clerk/clerk-react";
import {ArrowRightIcon, EnterIcon} from "@radix-ui/react-icons";

export default function AboutPage() {
    const {isLoaded, session, isSignedIn} = useSession()
    return (
        <>
            <div>
                <div className="mx-auto max-w-7xl px-6 py-14">
                    <div className="mx-auto max-w-4xl">
                        <Link to={"/"} className="flex justify-center">
                            <img src="/apple-touch-icon.png" alt="psmslnks logo"/>
                        </Link>
                        <Heading as={"h1"}
                                 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">About</Heading>

                        <Text as={"p"}>Welcome to <strong>Possum's Links</strong>, your ultimate visual bookmarks tool for organizing and managing your
                            favorite web links with ease and efficiency. Our application is designed to enhance your
                            bookmarking experience through a variety of powerful and intuitive features.</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Key
                            Features</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">
                            <li><strong className="font-semibold text-gray-900">Visual Bookmarks with
                                Thumbnails</strong>:
                                Easily identify your saved links at a glance with
                                visually appealing thumbnails.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Customizable Categories</strong>:
                                Organize your bookmarks into categories that you can name and
                                color-code to suit your preferences.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Search and Filtering</strong>: Quickly
                                find the bookmarks you need with our robust search and
                                filtering options.
                            </li>
                            <li><strong className="font-semibold text-gray-900">High Performance</strong>: Enjoy a
                                seamless and swift experience thanks to our optimized
                                performance.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Data Import/Export</strong>: Transfer
                                your bookmarks effortlessly with our simple import and
                                export functionality.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Ad-Free Experience</strong>: Focus on
                                what matters without the distraction of advertisements.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Customizable Appearance</strong>:
                                Tailor the look and feel of the application to match your
                                personal style.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Our
                            Mission</Heading>

                        <Text as={"p"}>We aim to provide a user-friendly and efficient way to keep
                            your online
                            resources organized. We believe that managing bookmarks should be a smooth and enjoyable
                            process, free from unnecessary clutter and distractions.</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Why
                            Choose Us?</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">
                            <li><strong className="font-semibold text-gray-900">Ease of Use</strong>: Our intuitive
                                interface makes it easy for anyone to start organizing
                                their bookmarks immediately.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Flexibility</strong>: With customizable
                                categories and appearance, you can personalize your
                                bookmarking experience to fit your unique needs.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Speed</strong>: Our application is
                                designed to be fast, ensuring you can access and manage your
                                bookmarks without any delays.
                            </li>
                            <li><strong className="font-semibold text-gray-900">Privacy and Security</strong>: We
                                prioritize your privacy and ensure your data is handled
                                securely.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Get
                            Started Today!</Heading>

                        <Text as={"p"}>Experience the convenience and efficiency of Visual Bookmarks. Start
                            organizing
                            your web
                            links visually and enjoy a clutter-free browsing experience.</Text>
                        <br/>

                        <Text as={"p"}>Thank you for choosing Visual Bookmarks!</Text>

                        <br/>
                        
                        {isSignedIn ?
                            <Link
                                to="/bookmarks"
                                className="flex items-center gap-2 px-5 py-3 bg-[#0d3b66] text-sm text-white font-medium rounded-full"
                            >
                                Go to bookmarks
                                <ArrowRightIcon/>
                            </Link>
                            :
                            <div className="flex gap-3">
                                <Link
                                    to="/sign-up"
                                    className="flex items-center gap-2 px-5 py-3 border-[1px] border-[#0d3b66] text-sm text-[#0d3b66] font-іуьшищдв rounded-full hover:bg-[#0d3b66] hover:text-white"
                                >
                                    Sign up
                                </Link>
                                <Link
                                    to="/sign-in"
                                    className="flex items-center gap-2 px-5 py-3 bg-[#0d3b66] text-sm text-white font-medium rounded-full hover:bg-[#f95738] hover:text-white"
                                >
                                    Log in
                                    <EnterIcon/>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}