import React from "react";
import {Link} from "react-router-dom";
import {Heading, Text} from "@radix-ui/themes";

export default function CookiePolicyPage() {
    return (
        <>
            <div>
                <div className="mx-auto max-w-7xl px-6 py-14">
                    <div className="mx-auto max-w-4xl">
                        <Link to={"/"} className="flex justify-center">
                            <img src="/apple-touch-icon.png" alt="psmslnks logo"/>
                        </Link>
                        <Heading as={"h1"} className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Cookie Policy</Heading>

                        <b className="text-sm font-bold leading-10 tracking-tight text-gray-500">Last Updated: 01.02.2024</b>

                        <Text as={"p"}>This Cookie Policy explains how Psmslnks.com
                            ("we," "us," or "our") uses
                            cookies and
                            similar tracking technologies when you visit our website or use our web application (the
                            "Service").</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">What Are Cookies</Heading>

                        <Text as="p">Cookies are small pieces of text sent by your web browser by a website you visit. A cookie
                            file
                            is stored
                            in your web browser and allows the Service or a third-party to recognize you and make your
                            next
                            visit
                            easier and the Service more useful to you.</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">How We Use
                            Cookies</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">When you use and access the Service,
                            we may place a number of cookies files in your web browser.
                            We use
                            cookies for the following purposes:

                            <li><strong className="font-semibold text-gray-900">To provide analytics</strong>:
                                We use cookies to track information about how the
                                Service is used so
                                that we
                                can make improvements. We use third-party services like Google Analytics to collect this
                                information.</li>
                            <li><strong className="font-semibold text-gray-900">To authenticate users</strong>:
                                We may use cookies to remember your login
                                credentials and preferences
                                when
                                you interact with the Service, such as when you use authentication services like Clerk.
                            </li>
                        </ul>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Your Choices Regarding
                            Cookies</Heading>

                        <Text as={"p"}>If you'd like to delete cookies or instruct your web browser to
                            delete or refuse cookies, please
                            visit
                            the help pages of your web browser. Please note, however, that if you delete cookies or
                            refuse
                            to accept
                            them, you might not be able to use all of the features we offer, you may not be able to
                            store
                            your
                            preferences, and some of our pages might not display properly.</Text>

                        <Heading as={"h2"} className="text-xl font-bold leading-10 tracking-tight text-gray-900">Changes to This Cookie
                            Policy</Heading>

                        <Text as={"p"}>We may update our Cookie Policy from time to time. We will notify
                            you of any changes by posting
                            the new
                            Cookie Policy on this page.</Text>
                    </div>
                </div>
            </div>
        </>
    )
}