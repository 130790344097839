const initialState = { category: null }; // Or whatever default you want to set

export const CREATE_CATEGORY_MODAL_OPEN = 'CREATE_CATEGORY_MODAL_OPEN';
export const CREATE_CATEGORY_MODAL_CLOSED = 'CREATE_CATEGORY_MODAL_CLOSED';

interface Action {
    type: string;
    payload?: any;
}
export const createCategoryModalReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case CREATE_CATEGORY_MODAL_OPEN:
            return { ...state, category: action.payload, isOpen: true };
        case CREATE_CATEGORY_MODAL_CLOSED:
            return { ...state, isOpen: false };
        default:
            return state;
    }
};