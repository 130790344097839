import {SignIn} from "@clerk/clerk-react"
import React from "react";

export default function SignInPage() {
    return (<>
            <div className="min-h-screen flex justify-center items-center">
                <div className="max-w-md w-full p-6 bg-white shadow-md rounded-md">
                    <SignIn/>
                </div>
            </div>
        </>
    )
}