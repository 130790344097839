import React, {useState} from 'react';
import BookmarkEntity from "../interfaces/BookmarkEntity";
import {apiDeleteBookmark} from "../interfaces/API";
import {Button, IconButton, Tooltip} from "@radix-ui/themes";
import {ArrowRightIcon, Pencil1Icon, TrashIcon} from "@radix-ui/react-icons";

const Card = ({bookmark, refetchBookmarks}: { bookmark: BookmarkEntity, refetchBookmarks: () => void }) => {
    const [showIcons, setShowIcons] = useState(false);

    const handleEditClick = () => {
        // Implement edit action
        console.log('Edit action clicked for bookmark:', bookmark);
    };

    const handleDeleteClick = async () => {
        await apiDeleteBookmark(bookmark.id);
        refetchBookmarks();
    };

    return (
        <div
            className="flex flex-1 flex-col relative"
            onMouseEnter={() => setShowIcons(true)}
            onMouseLeave={() => setShowIcons(false)}
        >
            {showIcons && (
                <div className="absolute top-1 left-0 right-1 z-10 flex justify-end">
                    <Tooltip content="Edit bookmark">
                        <IconButton radius="full" className="card-iconBtn" onClick={handleEditClick}>
                            <Pencil1Icon/>
                        </IconButton>
                    </Tooltip>
                    &nbsp;
                    <Tooltip content="Delete bookmark">
                        <IconButton radius="full" className="card-iconBtn" onClick={handleDeleteClick}>
                            <TrashIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            )}

            <a href={bookmark.url} rel="noreferrer" target="_blank">
                <div className="relative">
                    <img className="mx-auto w-[100%] flex-shrink-0 rounded-tl-lg rounded-tr-lg"
                         src={bookmark.image_url} alt={bookmark.header}
                         width={"200px"}
                         height={"150px"}/>
                    {/*<div className="absolute inset-0 bg-white bg-opacity-30 hover:bg-transparent"/>*/}
                </div>
                <div className="flex flex-col justify-between p-4 h-[130px]">
                <span className="mt-1 text-xs line-clamp-3 text-gray-600 font-medium overflow-ellipsis overflow-hidden"
                      title={bookmark.description} data-te-ripple-color="light">
                    {bookmark.header}
                </span>
                    <Button
                        className="flex justify-between mt-2 pl-4 pr-2 py-1 text-xs rounded-full cursor-pointer cardBtn">
                        {bookmark.site_name}
                        <ArrowRightIcon/>
                    </Button>
                </div>
            </a>
        </div>
    )
        ;
};

export default Card;
