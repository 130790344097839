import {Outlet} from 'react-router-dom'
import React from "react"
import Footer from "../components/Footer";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}

export default function RootLayout() {
    return (
        <>
            <main className="flex-grow">
                <Outlet/>
            </main>
            <Footer/>
        </>
    )
}