import React from "react";
import {Heading, Text} from "@radix-ui/themes";
import {Link} from "react-router-dom";

export default function PrivacyPolicyPage() {
    return (
        <>
            <div>
                <div className="mx-auto max-w-7xl px-6 py-14">
                    <Link to={"/"} className="flex justify-center">
                        <img src="/apple-touch-icon.png" alt="psmslnks logo"/>
                    </Link>

                    <div className="mx-auto max-w-4xl">
                        <Heading as="h1" className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Privacy Policy</Heading>

                        <b className="text-sm font-bold leading-10 tracking-tight text-gray-500">Last Updated:
                            01.02.2024</b>

                        <Text as="p">Psmslnks.com ("we," "us," or "our") is committed to protecting the privacy and security of
                            your personal
                            information. This Privacy Policy describes how we collect, use, and disclose information
                            when you use
                            our website or web application (the "Service").</Text>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">Information We Collect</Heading>

                        <Text as="p">When you use our Service, we may collect certain information automatically through
                            third-party services
                            such as Google Analytics. This information may include your IP address, browser type,
                            operating system,
                            referring URLs, pages viewed, and other usage information. We use this information to
                            analyze trends,
                            administer the site, track users' movements around the site, and gather demographic
                            information about
                            our user base as a whole. We do not use cookies for this purpose.</Text>

                        <Text as="p">Additionally, when you use our Service, you may provide us with certain personal information,
                            such as
                            your name, email address, and other contact details when you sign up or authenticate using
                            Clerk. We use
                            this information to provide you with access to the Service and to communicate with you about
                            your
                            account.</Text>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">How We Use Your Information</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">We use the information we collect, including personal information, for the following
                            purposes:

                            <li>To provide, maintain, and improve our Service;</li>
                            <li>To personalize your experience and customize content;</li>
                            <li>To communicate with you about your account and respond to your inquiries;</li>
                            <li>To monitor and analyze usage of the Service and trends;</li>
                            <li>To comply with legal obligations and enforce our policies.</li>
                        </ul>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">Disclosure of Your Information</Heading>

                        <ul className="list-disc list-inside marker:text-gray-900">We may disclose your information, including personal information, to third parties in the
                            following
                            circumstances:

                            <li>With your consent;</li>
                            <li>To our service providers who assist us in providing the Service, such as Google
                                Analytics and
                                Clerk;
                            </li>
                            <li>To comply with legal obligations or respond to lawful requests from government
                                authorities;
                            </li>
                            <li>To protect and defend our rights, property, or safety or the rights, property, or safety
                                of
                                others.
                            </li>
                        </ul>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">Data Retention</Heading>

                        <Text as="p">We will retain your information for as long as necessary to fulfill the purposes outlined in
                            this Privacy
                            Policy unless a longer retention period is required or permitted by law.</Text>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">Your Choices</Heading>

                        <Text as="p">You may choose not to provide certain personal information, but this may limit your ability
                            to use
                            certain features of the Service.</Text>

                        <Heading as="h2" className="text-xl font-bold leading-10 tracking-tight text-gray-900">Changes to This Privacy Policy</Heading>

                        <Text as="p">We may update this Privacy Policy from time to time. We will notify you of any changes by
                            posting the new
                            Privacy Policy on this page.</Text>
                        
                    </div>
                </div>
            </div>
        </>
    )
}