import React, {useEffect, useRef} from 'react';

import {useState} from 'react'
import Cards from "../components/Cards";
import Tags from "../components/Tags";
import Navigation from "../components/Navigation";
import BookmarksPayload from "../interfaces/BookmarksPayload";
import {CLOSE_BOOKMARK_MODAL, OPEN_BOOKMARK_MODAL} from "../reducers/addBookmarkModalReducer";
import {useDispatch, useSelector} from 'react-redux';
import AddBookmarkModal from "../modals/AddBookmarkModal";
import {apiCreateBookmark, apiFetchBookmarks} from "../interfaces/API";
import AddBookmarkRequest from "../interfaces/AddBookmarkRequest";
import {BookmarkIcon, MagnifyingGlassIcon} from "@radix-ui/react-icons";
import {Button, TextField, Kbd} from "@radix-ui/themes";
import {RootState} from "../store";

const BookmarksPage: React.FC = () => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const config = useSelector((state: RootState) => state.applicationConfig);
    const isModalOpen = useSelector((state: RootState) => state.addBookmarkModal.isOpen);

    const [searchBoxValue, setSearchBoxValue] = useState("");
    const empty_payload: BookmarksPayload = {bookmarks: [], categories: []};
    const [bookmarksPayload, setBookmarksPayload] = useState<BookmarksPayload>(empty_payload);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");

    const fetchBookmarks = async () => {
        try {
            const response = await apiFetchBookmarks();
            const data: BookmarksPayload = await response.json();
            /*
             In this code, {...data} creates a new object with the same properties as data,
             ensuring a new reference is created.
             This should trigger a re-render of the component when the state is updated,
             and you should see the new bookmarks without having to manually reload the page.
             */
            setBookmarksPayload({...data});
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchBookmarks();
    }, [])

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            // For Alt+K on Windows/Linux or Cmd+K on macOS
            if ((event.altKey || event.metaKey) && event.key === 'k') {
                event.preventDefault(); // Prevent the default action to avoid any side effect
                searchInputRef.current?.focus(); // Focus the search input
            }

            // For Alt+A on Windows/Linux or Cmd+A on macOS
            if ((event.altKey || event.metaKey) && event.key === 'a') {
                event.preventDefault(); // Prevent the default action to avoid any side effect
                dispatch({type: OPEN_BOOKMARK_MODAL}); // Dispatch the action
            }

            // For Escape
            if (event.key === 'Escape') {
                event.preventDefault(); // Prevent the default action to avoid any side effect
                searchInputRef.current?.blur(); // Remove focus from the search input
            }
        }

        // Add event listener
        if (config.useKeyboardShortcuts) {
            window.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup event listener on component unmount
        return () => {
            if (config.useKeyboardShortcuts) {
            window.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [config.useKeyboardShortcuts]); // Empty dependency array means this effect runs once on mount

    return (
        <div className="sticky-dummy-container">
            {/*{isLoading && <SpinnerTransparent/>}*/}

            <div className="min-h-full sticky-dummy-sub-container">
                <Navigation/>

                <div className="py-10 space-y-5">

                        <div className="flex justify-between h-[40px] mx-auto max-w-7xl sm:px-6 md:px-7 lg:px-8">
                            <div className="flex flex-1 justify-start">
                                <Button
                                onClick={() => dispatch({type: OPEN_BOOKMARK_MODAL})}
                                size="3"
                                >
                                    <BookmarkIcon/> Add Bookmark { config.useKeyboardShortcuts && <Kbd size="2">⌘ A</Kbd> }
                                </Button>
                            </div>

                            <div className="flex flex-1 justify-end">
                                <TextField.Root
                                    placeholder="Search the bookmarks…"
                                    onChange={(e) => setSearchBoxValue(e.target.value)}
                                    size="3"
                                    ref={searchInputRef}
                                >
                                    <TextField.Slot>
                                        <MagnifyingGlassIcon height="16" width="16"/>
                                    </TextField.Slot>
                                    <TextField.Slot pr="3">
                                        { config.useKeyboardShortcuts && <Kbd size="1">⌘ K</Kbd> }
                                    </TextField.Slot>
                                </TextField.Root>
                                {/*<SearchBox onChangeHandler={setSearchBoxValue}/>*/}
                            </div>

                            {
                                bookmarksPayload?.categories.length > 0
                                && <AddBookmarkModal isOpen={isModalOpen}
                                                     categories={bookmarksPayload.categories}
                                                     onRequestClose={() => dispatch({type: CLOSE_BOOKMARK_MODAL})}
                                                     selected_category_id={selectedCategoryId}
                                                     onSaveBookmark={async (url, selected_category_id) => {
                                                         let req: AddBookmarkRequest = {
                                                             url: url,
                                                             // description: description,
                                                             category_ext_id: selected_category_id
                                                         };
                                                         await apiCreateBookmark(req);
                                                         dispatch({type: CLOSE_BOOKMARK_MODAL});
                                                         await fetchBookmarks(); // Refresh bookmarks
                                                     }}/>
                            }
                        </div>

                        <div className="mx-auto max-w-7xl sm:px-6 md:px-7 lg:px-8">
                            <Cards
                                bookmarks={bookmarksPayload.bookmarks}
                                config={config}
                                filter_value={searchBoxValue}
                                selected_category_id={selectedCategoryId}
                                bookmarks_refetch_function={fetchBookmarks}
                            />
                        </div>
                </div>
            </div>
            <Tags categories={bookmarksPayload.categories} setSelectedCategoryId={setSelectedCategoryId}/>
        </div>
    )
}

export default BookmarksPage;