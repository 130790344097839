const initialState = { category: null }; // Or whatever default you want to set

export const EDIT_CATEGORY_MODAL_OPEN = 'EDIT_CATEGORY_MODAL_OPEN';
export const EDIT_CATEGORY_MODAL_CLOSED = 'EDIT_CATEGORY_MODAL_CLOSED';

interface Action {
    type: string;
    payload?: any;
}
export const editCategoryModalReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case EDIT_CATEGORY_MODAL_OPEN:
            return { ...state, category: action.payload, isOpen: true };
        case EDIT_CATEGORY_MODAL_CLOSED:
            return { ...state, isOpen: false };
        default:
            return state;
    }
};