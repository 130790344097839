import * as React from 'react'
import {useAuth} from "@clerk/clerk-react"
import {Outlet, useNavigate} from "react-router-dom"
import Spinner from "../components/Spinner";

export default function DashboardLayout() {
    const {userId, isLoaded} = useAuth()
    const navigate = useNavigate()

    React.useEffect(() => {
        if (isLoaded && !userId) {
            navigate("/sign-in")
        }
    }, [userId, isLoaded, navigate])

    if (!isLoaded || !userId) return (<>
        <Spinner/>
    </>)

    return (
        <Outlet/>
    )
}