import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import '@radix-ui/themes/styles.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'

// Import the layouts
import RootLayout from './layouts/root-layout'
import DashboardLayout from './layouts/dashboard-layout'

// Import the components
import IndexPage from './routes/Index'
import SignInPage from './routes/SignIn'
import SignUpPage from './routes/SignUp'
import BookmarksPage from './routes/Bookmarks'
import PrivacyPolicyPage from "./routes/PrivacyPolicy"
import TermsAndConditionsPage from "./routes/TermsAndConditions"
import CookiePolicyPage from "./routes/CookiePolicy"
import NotFound from "./routes/NotFound"
import CategoriesPage from "./routes/Categories"

import {Provider, useDispatch} from 'react-redux'
import { Action } from 'redux'
import store from './store'
import ReactModal from "react-modal"
import {Theme} from '@radix-ui/themes'
import SettingsPage from "./routes/Settings"
import {fetchConfigAsync} from "./actions/ApplicationConfigAction"
import {ThunkDispatch} from "redux-thunk"
import ApplicationConfig from "./interfaces/ApplicationConfig"
import ContactUsPage from "./routes/ContactUs"
import {ClerkProvider, useSession} from "@clerk/clerk-react";
import AboutPage from "./routes/About";

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!publishableKey) {
    throw new Error("Missing Publishable Key")
}

const router = createBrowserRouter([
    {
        element: <RootLayout/>,
        children: [
            {path: "/", element: <IndexPage/>},
            {path: "/sign-in", element: <SignInPage/>},
            {path: "/sign-up", element: <SignUpPage/>},
            {path: "/privacy-policy", element: <PrivacyPolicyPage/>},
            {path: "/terms-and-conditions", element: <TermsAndConditionsPage/>},
            {path: "/contact-us", element: <ContactUsPage/>},
            {path: "/cookie-policy", element: <CookiePolicyPage/>},
            {path: "/about", element: <AboutPage/>},
            {
                element: <DashboardLayout/>,
                children: [
                    {path: "/bookmarks", element: <BookmarksPage/>},
                    {path: "/categories", element: <CategoriesPage/>},
                    {path: "/settings", element: <SettingsPage/>},
                ]
            },
            {path: "*", element: <NotFound/>}
        ]
    }
])

type AppDispatch = ThunkDispatch<ApplicationConfig, unknown, Action>;

const App = () => {
    const {isLoaded, session, isSignedIn} = useSession()
    const dispatch: AppDispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (isLoaded && isSignedIn) {
            dispatch(fetchConfigAsync());
        }
    }, [isLoaded, isSignedIn, dispatch]);

    return (
        <RouterProvider router={router}/>
    );
};

ReactModal.setAppElement(document.getElementById('root')!)

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <Theme
                accentColor="indigo"
                grayColor="auto"
                panelBackground="translucent"
                scaling="100%"
                radius="medium"
                appearance="light"
                hasBackground={true}
            >
                <ClerkProvider publishableKey={publishableKey as string} afterSignInUrl={'/bookmarks'} afterSignUpUrl={'/bookmarks'}>
                    <App />
                </ClerkProvider>
            </Theme>
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
