import React from 'react';
import CategoryEntity from "../interfaces/CategoryEntity";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {RootState} from "../store";

interface TagsProps {
    categories: CategoryEntity[];
    setSelectedCategoryId: (id: string) => void;
}

export default function Tags({categories, setSelectedCategoryId}: TagsProps) {
    const config = useSelector((state: RootState) => state.applicationConfig);

    return (
        <div className="bg-white bg-opacity-80 sticky-tags-container">
            <div className="mx-auto max-w-7xl px-6 py-20 sm:py-2 lg:px-8 text-center sticky-tags">
                <button
                    type={"button"}
                    key={"default_category"}
                    className={
                        classNames(
                            'relative inline-flex items-center rounded-md px-2 m-1 py-1 text-l font-medium hover:bg-gray-100',
                        )
                    }
                    style={{backgroundColor: 'gray', color: '#ffffff'}}
                    onClick={() => setSelectedCategoryId('')}
                >
                    {"All"}
                    {/*<div*/}
                    {/*    className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-black bg-white border-1 border-black rounded-full -top-2 -end-2 dark:border-gray-900">*/}
                    {/*    {category.bookmarks_count}*/}
                    {/*</div>*/}
                </button>
                {
                    categories.map((category) => (
                        <button
                            type={"button"}
                            key={category.id}
                            className={
                                classNames(
                                    'relative inline-flex items-center rounded-md px-2 m-1 py-1 text-l font-medium hover:bg-gray-100',
                                )
                            }
                            style={{backgroundColor: category.background_color, color: category.text_color}}
                            onClick={() => setSelectedCategoryId(category.id)}
                        >
                            {category.name}
                            {config.showNumberOfBookmarksInCategory &&
                                <div
                                    className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-black bg-white border-1 border-black rounded-full -top-2 -end-2 dark:border-gray-900">
                                    {category.bookmarks_count}
                                </div>
                            }
                        </button>
                    ))
                }
            </div>
        </div>
    )
}
